.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
    background-color: #39374e !important;
    color: #babdbf !important;
    border-color: #39374e !important;
}

.nav-link-gdc-selected {
    font-weight: bold !important;
}

div.font-montserrat {
    font-family: "Montserrat", sans-serif;
}

div.font-roboto {
    font-family: "Robot", sans-serif;
}

div.font-opensans {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

div.font-poppins {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}

/* div.rtl{ */
div.rtl {
    direction: rtl !important;
    text-align: right;
}

.sticky-top {
    width: calc(100% - 280px);
    position: fixed;
}

.marginTop {
    margin-top: 7.2% !important;
}

::-moz-selection {
    color: #ffffff;
    background: #42436b;
}

::selection {
    color: #ffffff;
    background: #42436b;
}

a,
.btn-link {
    color: #42436b;
}

.text-green,
.text-info,
.text-success,
.text-danger,
.text-primary,
.text-warning,
.mail-star.active,
.page-title {
    color: #42436b !important;
}

.badge-default {
    background: #3f3d56;
    color: #f1f1f1;
}

.text-white {
    color: #ffffff;
}

.loader {
    color: #42436b;
}

.badge-primary {
    background-color: #42436b;
}

.btn-primary {
    background: #42436b;
    color: #ffffff;
    border-color: #42436b;
}

.btn-info {
    background-color: #ed1c24;
    border-color: #ed1c24;
}

.nav-tabs .nav-link.active {
    border-color: #42436b;
    color: #42436b;
}

.form-control:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(66, 67, 107, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(66, 67, 107, 0.25);
}

.card,
.dropify-wrapper,
.btn-primary {
    border: 0;
}

.bg-primary,
.bg-info,
.bg-warning,
.btn-success {
    background-color: #42436b !important;
    border: transparent;
}

.bg-success,
.bg-danger,
.badge-success,
.progress-bar,
.btn-danger,
.btn-warning {
    background-color: #ed1c24;
    border: transparent;
}

.btn-outline-primary {
    color: #42436b;
    border-color: #42436b;
}

.btn-outline-primary:hover {
    background-color: #42436b;
    border-color: #42436b;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before,
.custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    border-color: #42436b;
    background-color: #42436b;
}

.custom-switch-input:checked ~ .custom-switch-indicator {
    background: #42436b;
}

.selectgroup-input:checked + .selectgroup-button {
    border-color: #42436b;
    color: #42436b;
    background: rgba(66, 67, 107, 0.2);
}

.imagecheck-figure:before {
    color: #ed1c24 !important;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active,
.wizard > .actions a,
.wizard > .actions a:hover {
    background: #42436b;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover {
    background: rgba(66, 67, 107, 0.2);
    color: #42436b;
}

.table th {
    color: #2c2945;
}

.table.table-custom td,
.table.table-custom th {
    background: #f1f1f1;
}

.right_chat li.online .status {
    background: #42436b;
}

.bg-blue,
.bg-azure,
.bg-indigo,
.bg-purple,
.bg-red,
.bg-orange {
    background-color: #42436b !important;
}

.bg-pink,
.bg-yellow,
.bg-lime,
.bg-green,
.bg-teal,
.bg-cyan {
    background-color: #ed1c24 !important;
}

.bg-light-blue,
.bg-light-azure,
.bg-light-indigo,
.bg-light-lime,
.bg-light-green,
.bg-light-teal {
    background-color: rgba(66, 67, 107, 0.1);
    color: #42436b;
}

.bg-light-pink,
.bg-light-red,
.bg-light-orange,
.bg-light-yellow,
.bg-light-cyan {
    background-color: rgba(237, 28, 36, 0.1);
    color: #ed1c24;
}

.bg-light-purple {
    background-color: rgba(228, 189, 81, 0.1);
    color: #e4bd51;
}

.bg-light-gray {
    background-color: rgba(102, 106, 109, 0.1);
    color: #666a6d;
}

.avatar.avatar-blue {
    background-color: rgba(66, 67, 107, 0.1);
    color: #42436b;
}

.avatar.avatar-azure {
    background-color: rgba(66, 67, 107, 0.1);
    color: #42436b;
}

.avatar.avatar-indigo {
    background-color: rgba(66, 67, 107, 0.1);
    color: #42436b;
}

.avatar.avatar-purple {
    background-color: rgba(66, 67, 107, 0.1);
    color: #42436b;
}

.avatar.avatar-pink {
    background-color: rgba(66, 67, 107, 0.1);
    color: #42436b;
}

.avatar.avatar-red {
    background-color: rgba(66, 67, 107, 0.1);
    color: #42436b;
}

.avatar.avatar-orange {
    background-color: rgba(66, 67, 107, 0.1);
    color: #42436b;
}

.avatar.avatar-yellow {
    background-color: rgba(237, 28, 36, 0.1);
    color: #ed1c24;
}

.avatar.avatar-lime {
    background-color: rgba(237, 28, 36, 0.1);
    color: #ed1c24;
}

.avatar.avatar-green {
    background-color: rgba(237, 28, 36, 0.1);
    color: #ed1c24;
}

.avatar.avatar-teal {
    background-color: rgba(237, 28, 36, 0.1);
    color: #ed1c24;
}

.avatar.avatar-cyan {
    background-color: rgba(237, 28, 36, 0.1);
    color: #ed1c24;
}

body {
    background-color: #f1f1f1;
    color: #2c2945;
}

.page-header .right .nav-pills .nav-link {
    color: #2c2945;
}

#header_top .brand-logo {
    background: #42436b;
}

.header {
    background: #42436b;
}

.header .nav-tabs .nav-link {
    color: #ffffff;
}

.header .nav-tabs .nav-link.active {
    border-color: #ffffff;
}

.header .dropdown-menu .dropdown-item:hover,
.header .dropdown-menu .dropdown-item.active {
    color: #42436b;
}

.page .section-white,
.page .section-body {
    background: #f1f1f1;
}

#header_top .nav-link {
    color: #2c2945;
}

.header_top.dark {
    background-color: #39374e !important;
}

.header_top.dark .nav-link {
    color: #f1f1f1 !important;
}

.card {
    background-color: #ffffff;
}

.card .card-options a {
    color: #42436b;
}

.card .card-options a.btn {
    color: #ffffff;
}

.card.card-fullscreen {
    background-color: #ffffff !important;
}

.metismenu a {
    color: #2c2945;
}

.metismenu a:hover {
    color: #42436b;
    border-color: #42436b;
}

.metismenu .active > a {
    color: #42436b;
    border-color: #42436b;
}

.metismenu .active ul .active a {
    color: #42436b;
    background: transparent;
}

.metismenu.grid > li.active > a,
.metismenu.grid > li > a:hover {
    background: rgba(66, 67, 107, 0.1);
    color: #42436b;
}

.inbox .detail {
    background: #ffffff;
}

.file_folder a {
    background: #ffffff;
    border-color: #f1f1f1;
}

.auth .auth_left {
    background: #ffffff;
}

.gender_overview {
    background: #f1f1f1;
}

.table-calendar-link {
    background: #42436b;
}

.table-calendar-link:hover {
    background: #39374e;
}

.table-calendar-link::before {
    background: #39374e;
}

.theme_div {
    background: #ffffff;
}

.ribbon .ribbon-box.green {
    background: #ed1c24;
}

.ribbon .ribbon-box.green::before {
    border-color: #ed1c24;
    border-right-color: transparent;
}

.ribbon .ribbon-box.orange {
    background: #42436b;
}

.ribbon .ribbon-box.orange::before {
    border-color: #42436b;
    border-right-color: transparent;
}

.ribbon .ribbon-box.pink {
    background: #ed1c24;
}

.ribbon .ribbon-box.pink::before {
    border-color: #ed1c24;
    border-right-color: transparent;
}

.ribbon .ribbon-box.indigo {
    background: #e4bd51;
}

.ribbon .ribbon-box.indigo::before {
    border-color: #e4bd51;
    border-right-color: transparent;
}

.text-orange {
    color: #e4bd51 !important;
}

.tag-info {
    background: #42436b;
}

.tag-success,
.tag-green {
    background: #42436b;
}

.tag-orange {
    background: #ed1c24;
}

.tag-danger {
    background: #ed1c24;
}

.bg-success {
    background: #42436b !important;
}

.bg-danger {
    background-color: #ed1c24 !important;
}

.auth .auth_left {
    background: #39374e;
}

.auth .auth_left .card {
    padding: 20px;
    right: -150px;
    z-index: 99999;
}

@media only screen and (max-width: 1024px) {
    .auth .auth_left .card {
        right: -50px;
    }
}

@media only screen and (max-width: 992px) {
    .auth .auth_left .card {
        right: auto;
    }
}

.top_dark {
    background-color: #39374e !important;
}

.sidebar_dark #left-sidebar {
    background-color: #39374e !important;
}

.sidebar_dark #header_top .nav-link {
    color: #f1f1f1 !important;
}

.sidebar_dark .sidebar .metismenu .active > a {
    color: #e4bd51;
}

.sidebar_dark .sidebar .metismenu .active ul .active a {
    color: #e4bd51;
}

.iconcolor #header_top .nav-link {
    color: #e4bd51 !important;
}

.gradient .custom-switch-input:checked ~ .custom-switch-indicator {
    background: linear-gradient(45deg, #42436b, #ed1c24) !important;
}

.gradient .metismenu.grid > li.active > a,
.gradient .metismenu.grid > li > a:hover {
    background: linear-gradient(45deg, #42436b, #42436b) !important;
    color: #ffffff;
    border: 0;
}

.gradient .btn-primary {
    background: linear-gradient(45deg, #42436b, #ed1c24) !important;
    border: 0;
}

.gradient .btn-dark {
    background: linear-gradient(45deg, #808488, #333537) !important;
}

.gradient .bg-success,
.gradient .bg-danger,
.gradient .badge-success,
.gradient .progress-bar,
.gradient .btn-danger {
    background: linear-gradient(45deg, #42436b, #ed1c24) !important;
}

.metismenu.grid > li > a {
    border-color: #42436b;
}

.small-txt {
    color: #706e85;
}

.card {
    border-radius: 0px;
}

.form-control {
    border-radius: 0px;
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.multiselect {
    border: 1px solid #e8e9e9 !important;
}

.table-head-search .form-control {
    border: none !important;
    border-radius: 0px;
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn {
    height: 34px !important;
    border: none;
    border-radius: 0px;
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.custom-btn .btn {
    height: 37px !important;
}

.small-txt {
    font-size: 0.7rem;
}

.table tbody td,
.table tbody th {
    padding: 10px 5px;
}

.table-nav span {
    font-size: 0.7rem;
    padding-top: 4px;
}

.table-nav i {
    font-size: x-large;
}

.overlay-btn-position {
    position: absolute;
    top: 15px;
    right: 0px;
    z-index: 99;
}

.overlay-btn .overlay-ico {
    font-size: 87.5%;
    border: 1px solid;
    border-color: #e8e9e9;
    border-radius: 20px;
    padding: 5px;
    margin-right: 0px;
    cursor: pointer;
}

.horizontal-nav .nav-tabs {
    white-space: nowrap;
    display: block !important;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.horizontal-nav .nav-tabs .nav-item {
    display: inline-block;
}

.modal-dialog .modal-title {
    font-size: 0.875rem;
    text-transform: uppercase;
    padding-top: 5px;
}

.modal-dialog .modal-content {
    border: none;
    border-radius: 0px;
}

.table-head th {
    color: #808488;
    text-transform: none;
    padding-left: 2px;
}

.tick {
    font-size: 1.125rem;
}

.green {
    color: #21ba45;
}

.bg-green {
    background: #21ba45 !important;
}

.red {
    color: #db2828;
}

.header-brand {
    width: 38px;
}

.header-brand .brand-logo {
    padding: 5px !important;
}

.form-label-small {
    float: left;
    color: #42436b !important;
    font-size: 12px;
}

.custom-switch-description {
    color: #42436b !important;
}

.border-hr {
    border: 1px solid #42436b;
}

.custom-switch-indicator {
    cursor: pointer !important;
}

.close {
    border: none !important;
    border-radius: 0px;
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.content-area {
    min-height: 75vh !important;
    overflow: auto;
}

.custom-icon {
    font-size: 15px;
}

.custom-icon-2 {
    font-size: 13px;
}

.modal-list {
    max-height: 500px;
    overflow: auto;
}

.modal-list ul {
    list-style: none;
    margin: 0;
    padding: 0px;
}

.modal-list ul li {
    margin-left: 0px;
    padding-left: 0px;
    line-height: 40px !important;
}

.modal-height {
    max-height: 420px !important;
    overflow: auto;
}

.h-scroll-active {
    white-space: nowrap;
    display: block !important;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.h-scroll-active .h-item {
    display: inline-block;
}

.c-pointer {
    cursor: pointer;
}

.icon-lg {
    font-size: 1.5rem;
}

.ast-table table thead th {
    padding-left: 20px !important;
}

.ast-table table tbody td {
    padding-left: 20px !important;
    padding-right: 20px;
}

.attandance-table table thead tr th {
    padding-top: 14px;
    padding-bottom: 10px;
    text-align: center;
}

.leave-table table tbody tr td {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

.c-green {
    background: #28a745;
    color: white;
}

.c-txt-green {
    color: #28a745;
}

.c-txt-green {
    color: #28a745;
}

.c-red {
    background: #ed1c24;
    color: white;
}

.c-blue {
    background: #42436b;
    color: white;
}

.c-txt-blue {
    color: #42436b;
}

.c-lgt-blue {
    background: #6d6e95;
    color: white;
}

.c-gray {
    background: #333333;
    color: white;
}

.c-orange {
    background: #f15e24;
    color: white;
}

.c-txt-orange {
    color: #f15e24;
}

.c-light-gray {
    color: #babdbf;
}

.invo-txt {
    color: #42436b;
    cursor: pointer;
}

.table-checkbox-head {
    margin-right: 24px;
}

.table-checkbox {
    margin-right: 15px;
    margin-left: 0px !important;
    padding: 0px !important;
}

.print-button {
    font-size: 24px;
}

.close-btn {
    padding-top: 30px !important;
    font-size: 18px;
}

.close-btn-2 {
    padding-top: 6px;
    font-size: 18px;
}

.close-btn-3 {
    padding-top: 30px;
    font-size: 18px;
}

@media (min-width: 767px) {
    .close-btn3-md {
        padding-top: 6px !important;
    }
}

.varient-button {
    font-size: 1.125rem;
}

.fav-star {
    font-size: 1.125rem;
}

.varient-list {
    padding: 11px 0px 0px 9px;
    margin-bottom: 13px;
}

.cursor {
    cursor: pointer !important;
}

.top-layer {
    z-index: 999 !important;
}

.inw-s {
    width: 150px !important;
}

.inw-m {
    width: 200px !important;
}

.inw-l {
    width: 300px !important;
}

.t-pd-s {
    padding: 0px 40px !important;
}

.t-pd-m {
    padding: 0px 80px !important;
}

.t-pd-l {
    padding: 0px 180px !important;
}

.t-h-10 {
    max-height: 500px;
    overflow: auto;
}

.barcode-preview {
    max-height: 66vh;
    overflow: scroll;
}
/*# sourceMappingURL=ast-ui.css.map */

.h-75-scroll {
    height: 75vh;
    overflow: auto;
}
.h-80-scroll {
    height: 83vh;
    overflow: auto;
}

.h-85-scroll {
    height: 88vh;
    overflow: auto;
}

.h-min-80 {
    height: 80vh;
}

.user-alert {
    position: fixed;
    top: 20px;
    right: 35px;
    display: inline;
    z-index: 1111;
}

.modal-dialog {
    z-index: 9999 !important;
}
.modal-backdrop {
    z-index: 1;
    background-color: rgba(0,0,0, .8) !important;
}
.react-datepicker-wrapper {
    width: 100% !important;
}
.react-datepicker__close-icon::after {
    background-color: #42436b !important;
}
.tbl-journal td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 20px;
}
.tbl-journal th {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.scroll-overflow {
    overflow: scroll;
}
.tbl-grey-head {
    background-color: #F7F7F7;
    font-weight: 600;
}
.tbl-sub {
    font-weight: 500;
}
.pl-30 {
    padding-left: 30px !important;
}
.pl-60 {
    padding-left: 60px !important;
}
.data-error {
    color: #B94A48;
    background-color: #f2dede40;
    border: 1px solid #EED3D7;
}
.lbl-error {
    color: #B94A48;
}

.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 3px 1em 3px .4em;
    font-size: 14px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  